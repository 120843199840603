import React, { useState } from 'react';
import { css } from '@emotion/react';
import Form from './form';

export default function BusinessForm({ subtitle, className }) {
  const [buttonText, setButtonText] = useState('Submit');

  async function onSubmit(e, data) {
    e.preventDefault();

    setButtonText('Submitting...');

    const response = await window
      .fetch('/api/enquire', {
        method: 'POST',
        body: data,
      })
      .then(res => res.json());

    setButtonText(response);
  }

  return (
    <Form
      className={className}
      title="Business Enquiries"
      subtitle={subtitle}
      fields={[
        {
          type: 'text',
          name: 'name',
          placeholder: 'Name*',
          required: true,
        },
        {
          type: 'tel',
          name: 'phone',
          placeholder: 'Phone',
          required: false,
        },
        {
          type: 'text',
          name: 'jobtitle',
          placeholder: 'Job Title*',
          required: true,
        },
        {
          type: 'email',
          name: 'email',
          placeholder: 'Email*',
          required: true,
        },
        {
          type: 'text',
          name: 'company',
          placeholder: 'Company*',
          required: true,
        },
        {
          type: 'text',
          name: 'twitter',
          placeholder: 'Twitter',
          required: false,
        },
        {
          type: 'textarea',
          name: 'message',
          placeholder: 'Message*',
          required: true,
        },
      ]}
      submitErrorMessage="Please enter all required fields to submit enquiry"
      buttonText={buttonText}
      onSubmit={onSubmit}
      css={css`
        padding: var(--margin11) var(--margin);

        @media (max-width: 1024px) {
          padding: var(--margin8) var(--gutter);
        }

        @media (max-width: 550px) {
          padding: var(--margin8) var(--gutterHalf);
        }
      `}
    />
  );
}
