import { graphql } from 'gatsby';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { css } from '@emotion/react';
import { ThemeContext } from '../components/theme-provider';
import SEO from '../components/seo';
import BusinessForm from '../components/business-form';

export default function Enquiries({ data }) {
  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    theme.setHeaderHide(false);
    theme.setLogoHide(false);
    theme.setLogoInvert(false);
    theme.setBackURL('/');
  }, []);

  return (
    <>
      <SEO
        title="Business Enquiries"
      />
      <BusinessForm 
        css={css`
          padding-top: var(--marginPageTop);
          padding-bottom: var(--marginPageTop);

          @media (max-width: 1024px) {
            padding-top: var(--marginPageTop);
            padding-bottom: var(--marginPageTop);
          }

          @media (max-width: 800px) {
            padding-top: var(--margin14);
            padding-bottom: var(--margin14);
          }
  
          @media (max-width: 550px) {
            padding-top: var(--margin);
            padding-bottom: var(--margin14);
          }
        `}
        subtitle={data.siteSettings.enquiryIntro}
      />
    </>
  );
}

export const query = graphql`
  query {
    siteSettings: sanitySiteSettings(_id: { regex: "/(drafts\\\\.)?siteSettings/" }) {
      enquiryIntro
    }
  }
`;
